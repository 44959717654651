.toggle-w {
  /* height: 100vh; */
  /* display: grid;
    place-items: center;
    margin: 0;
	background: #222; */
  right: 16px;
  position: absolute;
  display: flex;
  height: 68px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.toggle {
  height: 24px;
}

.delete-position-btn {
  height: 24px;
  width: 24px;
}

.input-t[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 48px;
  height: 24px;
  background: #f3f4f6;
  border-radius: 50px;
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  cursor: pointer;
  transition: 0.4s;
  margin: 0;
}

.input-t:checked[type="checkbox"] {
  background: var(--Main-Ym-green, #00df5f);
}

.input-t[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  top: 3px;
  left: 3px;
  background: #c6c9d1;
  border-radius: 50%;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  transform: scale(1.1);
  transition: 0.4s;
}

.input-t:checked[type="checkbox"]::after {
  background: #ffffff;
  left: 27px;
}

@media (min-width: 744px) {
  .toggle-w {
    right: 32px;
    height: 75px;
  }


}
@media (min-width: 992px) {
  .toggle-w {
    flex-direction: inherit;
    height: auto;
    column-gap: 8px;
    right: 36px;
  }

  .delete-position-btn {
    display: none;
  }

  .edit-order__item:hover .delete-position-btn {
    display: inherit;
  }
}
