.animation-02s-all {
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
}

.animation-02s-opacity {
    transition: opacity .2s ease-in-out;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
}

.animation-01s-all {
    transition: all .1s ease-in-out;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
}

.animation-1s-all {
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
}
