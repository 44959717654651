.order-btn-wrapper {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 4px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 12px;
    box-sizing: border-box;
}

.order-btn-wrapper .order-info-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}