.f-6 {
  display: flex;
  gap: 6px;
}

.f-8 {
  display: flex;
  gap: 8px;
}

.page-wrapper {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  /* width: -webkit-fill-available; */

  min-height: 100vh;

  /* height: 100vh; убрано чтобы не обрезалось на десктопе см стр меню */

  justify-content: start;
}
/* .orders-page {
  justify-content: start;
} */

.orders-wrapper {
  width: -webkit-fill-available;
  margin-bottom: 45px;
}

.orders {
  margin: 16px 16px 45px;
  display: grid;
  gap: 8px;
}

@media (min-width: 744px) {
  .page-wrapper {
    flex-direction: row;
  }

  .orders-wrapper {
    margin-bottom: inherit;
  }
}
