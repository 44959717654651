.info-value-wrapper {
    display: flex;
    height: 48px;
    padding: 4px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 12px;
    background: var(--gray-light, #F3F4F6);
    width: 100%;
}

.info-value-wrapper.not-full-width {
    width: initial;
}