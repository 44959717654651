.order {
  background: var(--Main-White, #fff);
  border-radius: 12px;
  padding: 16px;
  display: grid;
  row-gap: 16px;
}

.order__top {
  flex-wrap: wrap;
  align-items: center;
}

.order__title {
  color: var(--Text-Primary, #2c2d2e);
  font-family: InterDisplay-SemiBold, serif;
  font-size: 24px;
  /* font-style: normal; */
  font-weight: 700;
  /* line-height: normal; */
  letter-spacing: -0.4px;
  /* display: flex; */
  /* column-gap: 4px; */
  align-items: center;

  cursor: pointer;
}

.order__title {
  font-size: 20px;
  font-weight: 600;
}



.order__table,
.order__name-client {
  display: flex;
  column-gap: 10px;
}

.order__info,
.order__positions {
  display: grid;
  gap: 8px;
}

.order__positions {
  display: flex;
  flex-wrap: wrap;
}

.order__position {
  color: var(--Text-Primary, #2c2d2e);
  /* border-radius: 16px; */
  background: var(--Gray-Light, #f3f4f6);
  /* padding: 7px 12px; */
}

.order__comments div:last-child {
  color: var(--Text-Primary, #2c2d2e);
}

.order__comments,
.order__title,
.order__sum {
  display: flex;
  column-gap: 4px;
}

.order__btns {
  flex-direction: column;
}

.order__btn  {
  padding: inherit !important;
  /* width: 144px; */
}
@media (min-width: 560px) {
  .order__btns {
    flex-direction: inherit;
  }

  .order__btn  {
    width: 144px;
  }

}
@media (min-width: 992px) {
  .order__btns {
    justify-content: flex-end;
    /* flex-direction: inherit; */
  }
  .order__btn  {
    padding: 0 24px !important;
    width: auto;
  }
}

/* @media (min-width: 992px) {

} */
