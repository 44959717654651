.nav-bar {
  background: var(--Main-White, #fff);
  display: flex;
  justify-content: space-around;
  border-top: 1px solid var(--Gray-Light, #f3f4f6);
  bottom: 0;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 3;
}
.nav-bar__item {
  height: 24px;
  padding: 10px 0;
  align-items: center;
  cursor: pointer;
}

.nav-bar__item .mobile-and-desktop-btn {
  color: var(--Text-Secondary, #5d5e64);
}
.nav-bar__item:nth-of-type(1) {
  order: 1;
}
.nav-bar__title {
  display: none;
}

@media (min-width: 744px) {
  .nav-bar {
    width: auto;
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    border-top: none;
    border-right: 1px solid var(--Gray-Light, #f3f4f6);
    row-gap: 8px;
    justify-content: flex-start;
    z-index: inherit;
    position: inherit;
  }
  .nav-bar__item {
    width: 225px;
    display: flex;
    column-gap: 10px;
    padding: 12px 16px;
    border-radius: 12px;
  }

  .nav-bar .active {
    border-radius: 12px;
    background: var(--Transparent-items-Active-item, #f4f6f8);
    color: var(--Text-Primary, #2c2d2e);
  }

  .nav-bar__item:hover {
    background-color: #f2f4f5;
  }

  .nav-bar__item:active {
    background-color: #e7eaec;
  }

  .nav-bar .mobile-and-desktop-btns {
    color: var(--Text-Secondary, #5d5e64);
  }

  .nav-bar__item:nth-of-type(1) {
    order: 0;
  }

  .nav-bar__title {
    display: block;
  }

}
