.tag-button-wrapper {
    display: flex;
    flex-direction: row;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 16px;
    width: auto !important;
}